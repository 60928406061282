import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import Logo from '../../../assets/PillarFinal--white.svg';

export default function Hero() {
  
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "shutterstock_206350801.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  const heroBG = getImage(data.file);
  const stackedHeroBG = [`linear-gradient(rgba(47,47,47,0.7),rgba(47,47,47,0.7))`, heroBG];

  return (
    <div className="hero">
      <div className="hero--slide">
        <BgImage className="hero--slide__inner" image={stackedHeroBG} preserveStackingContext />
      </div>
      <div className="hero--caption">
        <div className="hero--caption__content">
          <h2>cuando los resultados importan</h2>
          <div className="h-line"></div>
          <h1 className="hidden">Dreyer Law Firm, PLLC</h1>
          <Logo />
          <p>Con abogados de lesiones personales agresivos, dedicados y experimentados disponibles, nuestra bufete es su solución.</p>
          <Link to="/contact-us" className="hero-btn">¡Contáctenos Ahora!</Link>
        </div>
      </div>
    </div>
  );
}
