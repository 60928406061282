import React, { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue)
  const handleChange = e => {
    const thisValue = e.target.value === 'checkbox' ? e.target.checked : e.target.value;
    setValue(thisValue)
  }
  return {
    value,
    onChange: handleChange,
  }  
}

export default function ContactForm() {
  // form names
  const name = useFormInput('');
  const phone = useFormInput('');
  const email = useFormInput('');
  const caseType = useFormInput('');
  const website = useFormInput('');
  const message = useFormInput('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [notification, setNotification] = useState('');

  // to stringify values
  const nameVal = name.value;
  const phoneVal = phone.value;
  const emailVal = email.value;
  const caseTypeVal = caseType.value;
  const websiteVal = website.value;
  const messageVal = message.value;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return
    }

    // form field validations
    if (!name.value) {
      return setNotification(`Please provide your name.`);
    } else if (!phone.value) {
      return setNotification(`Please provide your phone number.`);
    } else if (!email.value) {
      return setNotification(`Please provide your email address.`);
    } else if (!message.value) {
      return setNotification(`Please tell us a little about your case.`)
    }
    const token = await executeRecaptcha('homeContactEsp');

    const data = JSON.stringify({
      nameVal,
      phoneVal,
      emailVal,
      caseTypeVal,
      websiteVal,
      messageVal,
      token
    })

    fetch('/api/v1/contact/home-contact-esp', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-type': 'application/json'
      },
      body: data
    })
      .then(res => res.json())
      .then(data => {
        setNotification(data.msg)
      })
    
    setNotification('Data Sent')
  }


  return (
    <div className="contactform">
      <div className="contactform--header">
        <h3>Consulta Gratis</h3>
      </div>
      <div role="form" className="contactform--form" lang="en-US">
        <div className="screen-reader-response">
          <p role="status" aria-live="polite" aria-atomic="true"></p>
          <ul></ul>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="reviewBlock">
            {notification && <span>{notification}</span>}
          </div>
          <div className="contactform--wrapper">
            <div className="column-30">
              <span className="contactform--name">
                <input type="text" name="name" id="nameHomeContactEsp" aria-required="true" aria-invalid="false" placeholder="Nombre*" {...name} required />
              </span>
            </div>
            <div className="column-30">
              <span className="contactform--phone">
                <input type="text" name="phone" id="phoneHomeContactEsp" aria-required="true" aria-invalid="false" placeholder="Teléfono*" {...phone} required/>
              </span>
            </div>
            <div className="clear">
              <input type="text" name="website" id="websiteHomeContactEsp" {...website} />
            </div>
            <div className="column-30">
              <span className="contactform--casetype">
                <select name="caseType" id="caseTypeHomeContactEsp" aria-invalid="false" defaultValue={"default"} {...caseType}>
                  <option value="default" disabled selected>Areas de Bufete</option>
                  <option value="Personal Injury">Lesiones personales</option>
                  <option value="Criminal Defense">Defensa Criminal</option>
                  <option value="DWI / Traffic">DWI / Infracciones de Tráfico</option>
                </select>
              </span>
            </div>
            <div className="column-30">
              <span className="contactform--email">
                <input type="email" name="email" id="emailHomeContactEsp" aria-required="true" aria-invalid="false" placeholder="Tu correo electrónico*" {...email} required />
              </span>
            </div>
            <div className="clear"></div>
            <div className="column-60">
              <span className="contactform--message">
                <textarea name="message" id="contactFormMessageEsp" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Mensaje*" {...message} required></textarea>
              </span>
            </div>
            <div className="clear"></div>
            <div className="column-60">
              <input className="contactform--submit" type="submit" value="aplique" />
              <span className="spinner"></span>
            </div>
            <div className="clear"></div>
          </div>
          <div className="contactform--response" aria-hidden="true"></div>
        </form>
      </div>
    </div>
  )
}
