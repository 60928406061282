import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
// import AboutHighlights from './AboutHighlights';

export default function About() {

  const data = useStaticQuery(graphql`
    {
      cutout: file(relativePath: {eq: "hp2-man-1.png"}) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
      signature: file(relativePath: {eq: "white-paul.png"}) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
      background: file(relativePath: {eq: "giammarco-boscaro-380907-unsplash.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  // const cutoutProfile = getImage(data.cutout);
  // const signaturePng = getImage(data.signature);
  const aboutBG = getImage(data.background);
  const stackedAboutBG = [`linear-gradient(rgba(47,47,47,0.85),rgba(47,47,47,0.85))`, aboutBG];

  return (
    <BgImage className="about" image={stackedAboutBG} preserveStackingContext>
      {/* <div className="container">
        <AboutHighlights />
      </div>
      <div className="clear"></div> */}
      <div className="container">
        <div className="about--content">
          {/* About Intro */}
          <div className="about--content__item">
            <div className="about--content__wrapper">
              <div className="about--content__wrapper--inner">
                <div className="about--content__wrapper--title">
                  <h3>Sobre Dreyer Law</h3>
                </div>
                <span>En Dreyer Law, entendemos cómo los casos de lesiones graves y accidentes pueden afectar su vida. Hemos representado a miles de clientes que enfrentan difíciles desafíos personales y legales. En todos los casos, trabajamos diligentemente para buscar el resultado más favorable posible para nuestros clientes, mientras nos esforzamos por superar las expectativas de nuestros clientes en cuanto a un servicio personalizado.</span>
              </div>
            </div>
            <div className="about--content__signature">
              <div className="about--content__signature--wrapper">
                <div className="about--content__signature--frame">
                  {/* <GatsbyImage image={signaturePng} alt="Darryl & Sarah Dreyer" /> */}
                </div>
              </div>
            </div>
            <div className="about--content__caption">
              <div className="about--content__caption--inner">
                <div className="about--content__caption--title">
                  <h3>Darryl & Sarah Dreyer</h3>
                </div>
              </div>
            </div>
          </div>
          {/* Cutout photo */}
          <div className="about--content__image">
            <div className="about--content__image--inner">
              <div className="about--content__image--frame">
                {/* <GatsbyImage image={cutoutProfile} alt="Darryl & Sarah Dreyer" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BgImage>
  );
}
