import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import ContactForm from "./ContactFormEsp";


export default function ContactBlog() {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "statue-con.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
      allMdx(
        filter: {frontmatter: {category: {ne: "drunk driving"}, datum: {eq: "blog"}}}
        sort: {fields: frontmatter___date, order: DESC}
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              category
              date(formatString: "MMMM Do, YYYY")
              hero_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              title
            }
            id
            slug
          }
        }
      }
    }
  `)

  const contactBG = getImage(data.file);
  const stackedContactBG = [`linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8))`, contactBG];

  return (
    <BgImage Tag="section" image={stackedContactBG}className="contact-us-blog">
      <div className="container">
        <div className="contact-blog-wrapper">
          <ContactForm />
          <div className="blog">
            <div className="blog--wrapper">
              <div className="blog--header">
                <div className="blog--header__title-wrap">
                  <h3>Noticias Recientes</h3>
                </div>
              </div>
              <div className="blog--index">
                <div className="blog--index--wrapper">
                  <div className="blog--index--list">
                    {data.allMdx.edges.map(({ node }) => {
                      const heroImage = getImage(node.frontmatter.hero_image);

                      return (
                        <div className="blog--index--list-item" key={node.id}>
                          <div className="blog-thumb">
                            <GatsbyImage image={heroImage} alt={node.frontmatter.title} />
                          </div>
                          <div className="blog-content">
                            <h3>
                              <Link to={`/news/${node.slug}`}>
                                {node.frontmatter.title}
                              </Link>
                            </h3>
                            <div className="blog-content--meta">
                              <span>
                                {node.frontmatter.date}
                              </span>
                              <span>&nbsp;&bull;&nbsp;</span>
                              <span>
                                {node.frontmatter.category}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BgImage>
  );
}
