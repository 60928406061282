import { graphql } from 'gatsby';
import React from 'react';
import Seo from '../../components/layout/SEO';
import Layout from '../../components/layout/LayoutEsp';
import Hero from '../../components/home/Espanol/Hero';
import About from '../../components/home/Espanol/About';
import PracticeAreas from '../../components/home/Espanol/PracticeAreas';
import Pitch from '../../components/home/Espanol/Pitch';
import Partners from '../../components/home/Espanol/Partners';
import ContactBlog from '../../components/home/Espanol/ContactBlog';
import OurCases from '../../components/home/Espanol/OurCases';

const index = ({ data }) => {
  return (
    <Layout>
      <Seo
        lang='es'
        title='Abogados de Lesiones Personales en Newburgh'
        canonical='es/'
      />
      <Hero />
      <OurCases data={data.allResultsJson} />
      <Partners />
      <PracticeAreas />
      <About />
      <ContactBlog />
      <Pitch />
    </Layout>
  )
}

export const query = graphql`
  query {
    allResultsJson(limit: 3) {
      edges {
        node {
          award
          espCategory
          id
          espNarrative
        }
      }
    }
  }
`

export default index