import React from 'react'

export default function Results({ children }) {

  return (
    <div className="results">
      <div className="results--wrapper">
        { children }
      </div>
    </div>
  )
}
